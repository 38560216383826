import { defineStore } from "pinia";
import { useSessionStore } from "./sessionStore";

export const usePosStore = defineStore("pos", {
  state: () => ({
    hydrated: false,

    selectedCompany: {},
    selectedBranch: {},
    selectedPos: {},
    selectedWarehouse: null,
    lastPosStatus: {},
    posStatus: {},

    payments: {}, // Es la suma de los movimientos realizados con cada medio de pago
    paymentMethodsControl: [],
    message: '',

    countCash: 0,
    cashControl: {
      amount: 0,
      count_amount: 0,
      payment_method_id: 1,
      system_amount: 0,
    },
  }),
  persist: { paths: ["selectedCompany", "selectedBranch", "selectedPos", "selectedWarehouse"] },

  getters: {
    workOffLine() {
      return this.selectedCompany.config?.workOffLine;
    },

    hasVatDiscriminated() {
      return this.selectedCompany.config?.hasVatDiscriminated;
    },

    hasGpsControl() {
      return this.selectedCompany.config?.hasGpsEmployeeControl;
    },

    checkGpsControl() {
      return this.hasGpsControl && !useSessionStore().isAdmin;
    },

    hasBarcodeCamScanner() {
      return this.selectedCompany.config?.hasBarcodeCamScanner;
    },

    isPosOpen() {
      return this.selectedPos?.pos_status?.id === 1;
    },

    hasCashControl() {
      return this.selectedCompany.config?.hasCashRegisterControl;
    },

    cashControlStatus() {
      return this.hasCashControl && !this.isPosOpen ? true : false;
    },

    hasStockControl() {
      return this.selectedCompany.config?.hasStockControl;
    },

    hasStockAlert() {
      return this.selectedCompany.config?.hasStockAlert;
    },

    hasExpirationAlert() {
      return this.selectedCompany.config?.hasExpirationAlert;
    },

    // Habilitar consumidor final
    hasFreeCustomer() {
      return this.selectedCompany.config?.hasFreeCustomer;
    },

    hasGlobalDiscont() {
      return this.selectedPos.config?.hasGlobalDiscont;
    },

    hasAddCustomItem() {
      return this.selectedPos.config?.hasAddCustomItem;
    },

    hasAddService() {
      return this.selectedPos.config?.hasAddService;
    },

    hasPrintLastInvoice() {
      return this.selectedPos.config?.hasPrintLastInvoice;
    },

    printPaper() {
      return this.selectedPos.config?.printInvoicePaper;
    },

    globalPrintPaper() {
      return this.selectedBranch.config?.printInvoicePaper;
    },

    canPayInOV() {
      return this.selectedPos.branch?.config?.canPayInOV;
    },

    isSaleBlock() {
      return (!this.isPosOpen && this.hasCashControl) ?? false;
    },

    paymentMethods() {
      return this.selectedCompany.payment_methods ?? [];
    },

    computedPMControl() {
      return this.paymentMethodsControl.map((i) => {
        const result = computed(() => {
          return +i.count_amount - +i.system_amount;
        });
        const difference = result.value;
        return { ...i, difference };
      });
    },

    totalPosCount() {
      if (this.computedPMControl.length) {
        return (
          this.computedPMControl.reduce(
            (arg, itm) => +arg - +withDecimals(itm.difference, 2),
            0
          )
        );
      }
      return 0;
    },

    totalDifference() {
      if (this.computedPMControl.length) {
        return (
          this.computedPMControl.reduce(
            (arg, itm) => +arg + +withDecimals(itm.difference, 2),
            0
          ) + +this.countCash
        );
      }
      return 0;
    },
  },

  actions: {},
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePosStore, import.meta.hot));
