import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    hydrated: false,
    currentTheme: "dark",
    scrollPosition: {
      publicVmain: {},
    },

  }),

  persist: true,

  getters: {
    isDark: (state) => state.currentTheme === "dark",
  },
  actions: {},
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
