import { useGlobalStore } from "@/stores/globalStore";
import { useSessionStore } from "@/stores/sessionStore";

export const footerMessages = ref([]);

function showMessage(message, color = "green", time = 4000) {
  const id = Date.now();
  // Crear el mensaje con su timeout
  const newMessage = {
    id,
    text: message,
    color,
    modelValue: true,
    timeout: setTimeout(() => removeMessage(id), time) // Almacena el timeout en el objeto
  };

  footerMessages.value = [...footerMessages.value, newMessage];
  useGlobalStore().desactiveLoader();
}

function removeMessage(id) {
  const index = footerMessages.value.findIndex(msg => msg.id === id);
  if (index !== -1) {
    footerMessages.value.splice(index, 1); // Elimina sin perder reactividad
  }
}

export function showAlertMsg(msg = "Acción procesada con éxito", time = 4000) {
  showMessage(msg, "green", time);
}

export function showWarning(e, time = 5000) {
  showMessage(e ?? "Hubo un error", "yellow", time);
}

export async function showError(e, time = 5000) {
  if (e?.response?.status === 401) {
    useGlobalStore().buildLoader = false;
    await useSessionStore().clearStore();
    throw new Error("Sesión inválida.");
  }

  const errorMessage =
    e.response?.data?.errors ??
    e.response?.data?.message ??
    e.message ??
    "Hubo un error";

  showMessage(errorMessage, "red", time);
}

export function showSistemMsg(msg) {
  const debug = import.meta.env.debug;
  if (useSessionStore().isRoot && debug) {
    console.log(msg);
  }
}

export function showSistemError(e) {
  if (useSessionStore().isRoot) {
    console.error(e.msg);
    console.log(e.event);
  }
}
