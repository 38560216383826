import { createRouter, createWebHistory } from "vue-router";
import generatedRoutes from "~pages";
import { useSessionStore } from "@/stores/sessionStore";
import { usePosStore } from "@/stores/posStore";
import { useGeneralStore } from "@/stores/generalStore";

import { setupLayouts } from "virtual:generated-layouts";

const router = createRouter({
  history: createWebHistory(),
  routes: setupLayouts(generatedRoutes),
});

router.onError((error, to) => {
  console.log({ 'RouteError': error });
})

// Define the authMiddleware function
const authMiddleware = (to, from, next) => {
  // Check if the route exists in generatedRoutes
  const routes = generatedRoutes.reduce((acc, curr) => {
    if (curr.children) {
      curr.children.forEach(child => {
        const path = `${curr.path}/${child.path}`;
        acc.push(path)
      })
    }
    acc.push(curr.path);
    return acc;
  }, []);

  if (!routes.some(route => route === to.path.toLowerCase())) {
    // Redirect to NotFound.vue if not found
    next({ name: 'NotFound' });
  } else {
    next(); // Continue with normal navigation if route exists
  }
};

router.beforeEach(authMiddleware);

router.beforeEach(async (to, from) => {
  const sessionStore = useSessionStore();
  const generalStore = useGeneralStore();
  // const posStore = usePosStore();

  if (!appMounted.value) {
    await sessionStore.hydrate();
  }

  // Guard rules
  const requiresAuth = to.matched.some((r) => r.meta.requiresAuth);

  const sudoRoute = to.matched.some((r) => r.meta.critically === "sudo");
  const systemRoute = to.matched.some((r) => r.meta.critically === "system");
  const secureRoute = to.matched.some((r) => r.meta.critically === "secure");
  const noModule = computed(() => sudoRoute || secureRoute || systemRoute);

  const isLoggedIn = sessionStore.isLoggedIn;
  const isLoginRoute = to.path === "/login";

  // // Paginas incluidas para el guard de control de caja
  // const isFastSale = ["/FastSale", "/SaleOrder", "/InputPayment"].includes(
  //   to.path
  // );

  if (requiresAuth && !noModule.value && isLoggedIn) {
    let availableModule = useGeneralStore().modules.some(
      (i) => i.name === to.name
    );
    if (!availableModule) {
      showError({
        message:
          "El modulo al que desea acceder no esta vinculado con su cuenta",
      });
      return { path: "/login" };
    }
  }

  if (requiresAuth && !isLoggedIn) {
    sessionStore.destroyPiniaAndLS();
    return { path: "/login" };
  }

  // if (isLoggedIn && sudoRoute) {
  //   if (sessionStore.user?.role_id !== 1) {
  //     console.log(sessionStore.user);
  //     return { path: "/Desktop" };
  //   };
  // }

  // if (posStore.isSaleBlock && isFastSale) {
  //   return { path: "/PointOfSale" };
  // }
  if (generalStore.availableRoutes.length && !noModule.value) {
    const routeAddress = to.name.split('-')[0];
    if (!generalStore.availableRoutes.some((i) => i.includes(routeAddress))) {
      const title = to.meta?.title ?? '';
      showError({ message: "No puedes acceder a la ruta " + title });
      return { path: "/Desktop" }
    };
  }
  if (isLoggedIn && isLoginRoute) return { path: "/Desktop" };
});

const install = (app) => app.use(router);
export { install, router };
